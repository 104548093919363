import React from 'react';
import PropTypes from 'prop-types';
import './PrimaryOfferSection.scss';

function PrimaryOfferSection({
  title,
  text,
  link,
  linkText,
  image,
  className,
  buttonClassName,
}) {
  return (
    <section
      className={`relative bg-cf-primary md:bg-white overflow-hidden md:rounded ${className}`}>
      <div className="container relative  md:rounded md:bg-cf-primary overflow-hidden">
        <div className="flex flex-wrap flex-col-reverse lg:flex-row justify-end primary-offer-section py-[48px] md:pt-[43px] md:pb-[62px]">
          <div className="w-full lg:w-4/12 xl:w-1/2 pt-[32px] lg:pt-0 self-end mb-[-50px] md:mb-[-62px]">
            {image}
          </div>
          <div className="w-full lg:w-8/12 xl:w-1/2 lg:pl-[30px]  lg:self-center text-white relative z-[1]">
            <div className="mb-5 text-4md md:text-2lg font-semibold font-heading max-w-[500px]">
              {title}
            </div>
            <div className="mb-6 text-cf-light-cold-purple text-2.5sm md:text-md max-w-[500px]">
              {text}
            </div>
            <a
              className={`button button-green w-full md:w-auto wide-chevron ${buttonClassName}`}
              href={link}>
              {linkText} <span className="chevron" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
PrimaryOfferSection.defaultProps = {
  link: '!#',
  linkText: 'Create Account',
  className: '',
  buttonClassName: 'mb-8 md:mb-0',
};
PrimaryOfferSection.propTypes = {
  className: PropTypes.string,
  image: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  buttonClassName: PropTypes.string,
};
export default PrimaryOfferSection;
