import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './SolutionSlider.scss';

function SolutionSlider({ data, settings, baseImageUrl }) {
  return (
    <div className="solution-slider-slick ml-[5%] md:ml-0 md:mr-[-19px]">
      <Slider {...settings}>
        {data.map((d) => (
          <div
            className="w-full md:w-1/3 px-3 md:p-[19px] !flex h-full"
            key={d.id}>
            <div
              className={`bg-white overflow-hidden p-6 md:p-8 h-full ${
                d.imageEdge ? 'flex flex-col justify-between' : 'flex flex-col'
              }`}>
              <div className="">
                <div className="text-2md md:text-3md font-semibold">
                  {d.title}
                </div>
                <div className="text-2.5sm pt-3 pb-6">{d.text}</div>
              </div>
              <div
                className={
                  d.imageEdge
                    ? 'flex-1 flex  items-end'
                    : 'flex-1 flex items-center'
                }>
                <img
                  {...d.image}
                  src={
                    baseImageUrl.length
                      ? `${baseImageUrl}/${d.image.src}`
                      : d.image.src
                  }
                  alt=""
                  loading="lazy"
                  className={`w-full rounded ${
                    d.imageEdge ? '-mb-6 md:-mb-8' : ''
                  }`}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

SolutionSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      imageEdge: PropTypes.bool,
      image: PropTypes.shape({
        src: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  ),
  baseImageUrl: PropTypes.string,
  settings: PropTypes.shape({}),
};

SolutionSlider.defaultProps = {
  data: [],
  baseImageUrl: '',
  settings: {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.4,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  },
};

export default SolutionSlider;
