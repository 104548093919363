import React from 'react';
import Icon from '../components/Icon';
import SliderButton from '../components/SliderEasySplit/SliderButton';
import TabPanel from '../components/Tabs/TabPanel';

export const heroCheckList = [
  {
    id: 0,
    listItem: '120+ payment modes',
  },
  {
    id: 1,
    listItem: 'Mobile first platform',
  },
  {
    id: 2,
    listItem: '15 minute settlements',
  },
];
export const heroLogoData = [
  {
    id: 0,
    src: 'nykaa.png',
    width: '181',
    height: '60',
    className: 'w-[60px]',
    alt: 'Nykaa',
  },
  {
    id: 1,
    src: 'pharmeasy.png',
    width: '312',
    height: '60',
    className: 'w-[104px]',
    alt: 'PharmEasy',
  },
  {
    id: 2,
    src: 'raw.png',
    width: '198',
    height: '114',
    className: 'w-[66px]',
    alt: 'Raw Pressery',
  },
  {
    id: 3,
    src: 'knowhere.png',
    width: '315',
    height: '96',
    className: 'w-[105px]',
    alt: 'KnowWhere',
  },
  {
    id: 4,
    src: 'bookmyshow.png',
    width: '267',
    height: '87',
    className: 'w-[89px]',
    alt: 'bookmyshow',
  },
  {
    id: 5,
    src: 'furlenco.png',
    width: '345',
    height: '78',
    className: 'w-[115px]',
    alt: 'Furlenco',
  },
  {
    id: 6,
    src: 'meesho.png',
    width: '297',
    height: '90',
    className: 'w-[99px]',
    alt: 'Meesho',
  },
  {
    id: 7,
    src: 'happyeasygo.png',
    width: '378',
    height: '87',
    className: 'w-[126px]',
    alt: 'Happyeasygo',
  },
  {
    id: 8,
    src: 'vista-rooms.png',
    width: '387',
    height: '93',
    className: 'w-[129px]',
    alt: 'Vista Rooms',
  },
  {
    id: 9,
    src: 'bigbasket.png',
    width: '327',
    height: '98',
    className: 'w-[109px]',
    alt: 'Bigbasket',
  },
  {
    id: 10,
    src: 'pantaloons.png',
    width: '315',
    height: '84',
    className: 'w-[105px]',
    alt: 'Pantaloons',
  },
];
export const heroSliderSettings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 2000,
  variableWidth: false,
  slidesToShow: 8,
  infinite: true,
  rtl: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        rtl: true,
        autoplay: true,
        cssEase: 'linear',
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};

export const ecommerceStore = [
  {
    id: 0,
    title: 'Collect Online Payments',
    cards: [
      {
        id: 0,
        icon: 'payment-gateway.svg',
        name: 'Payment Gateway',
        text: 'Collect ecommerce payments easily on your website/app',
        href: 'https://www.cashfree.com/payment-gateway-india/',
      },
      {
        id: 1,
        icon: 'payment-links.svg',
        name: 'Payment Links',
        text: (
          <>
            Collect payments by sharing payment links. No website or
            app&nbsp;required
          </>
        ),
        href: 'https://www.cashfree.com/payment-links/',
      },
      {
        id: 2,
        icon: 'payment-forms.svg',
        name: 'Payment Forms',
        text: 'Create custom payment pages and collect payments instantly',
        href: 'https://www.cashfree.com/payment-forms/',
      },
      {
        id: 3,
        icon: 'auto-collect.svg',
        name: 'Auto Collect',
        text: 'Reconcile IMPS/NEFT/UPI payments',
        href: 'https://www.cashfree.com/auto-e-collect/',
      },
      {
        id: 4,
        icon: 'easy-split.svg',
        name: 'Easy Split',
        text: 'Manage marketplace payments',
        href: 'https://www.cashfree.com/easy-split/split-payment-gateway/',
      },
      {
        id: 5,
        icon: 'softPOS.svg',
        name: 'softPOS',
        text: <>Turn any android mobile into a POS&nbsp;machine</>,
        href: '/soft-pos/',
      },
      {
        id: 6,
        icon: 'subscriptions.svg',
        name: 'Subscriptions',
        text: 'Collect recurring payments',
        href: 'https://www.cashfree.com/recurring-payment/',
      },
    ],
  },
  {
    id: 1,
    title: 'Process Refunds',
    cards: [
      {
        id: 0,
        icon: 'cashgram.svg',
        name: 'Cashgram',
        text: 'Payout link when only mobile number or email id is known',
        href: 'https://www.cashfree.com/cashgram/',
      },
      {
        id: 1,
        icon: 'instant-refunds.svg',
        name: 'Instant Refunds',
        text: 'Refunds for online payments and CoD orders',
        href: 'https://www.cashfree.com/instant-refunds/',
      },
    ],
  },
  {
    id: 2,
    title: 'Manage Payouts',
    cards: [
      {
        id: 0,
        icon: 'payouts.svg',
        name: 'Payouts',
        text: (
          <>
            Add multiple sources of funds with Payouts and make disbursals. Send
            money to any bank account, UPI ID, Paytm instantly 24x7 even on a
            bank&nbsp;holiday
          </>
        ),
        href: '/payouts/',
      },
    ],
  },
];

const headingClass = 'mb-4 md:mb-[32px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
const imageClassName = 'w-full md:pr-[19px]';
export const paymentGatewayData = [
  {
    key: 0,
    heading: 'Widest checkout',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/payment-modes.png',
            width: '1220',
            height: '924',
          }}
          heading="120+ domestic & 100+ international payment modes"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <div className="max-w-[500px] text-base">
              Offer your customers the freedom to pay via any payment mode of
              their choice including card, netbanking, UPI, wallet, EMI and Pay
              Later options.
            </div>
          }
          footer={
            <a
              className="button button-green  mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://www.cashfree.com/payment-gateway-india/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Payment Links',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/social-media.png',
            width: '1220',
            height: '924',
          }}
          heading="Be where your customer are"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <div className="max-w-[500px] text-base">
              Add payment links to your Facebook, Twitter, Instagram product
              posts and collect payments without any integration effort with our
              ecommerce payments&nbsp;system.
            </div>
          }
          footer={
            <a
              className="button button-green  mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://www.cashfree.com/payment-links/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Integration',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/integration.png',
            width: '1220',
            height: '924',
          }}
          heading="Ready-to-use plug-ins"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <div className="max-w-[500px] text-base">
              Go live in 5 minutes with Cashfree Payment Gateway on your
              Shopify, Woocommerce or any other online store with our plug-ins
              and integrations for web & mobile checkout. Increase your paid
              orders by upto 35% by integrating Cashfree Payments with{' '}
              <a
                className="text-cf-green underline underline-offset-2"
                href="https://logisy.tech/partners/cashfree/"
                target="_blank"
                rel="noopener noreferrer">
                Logisy.
              </a>
            </div>
          }
          footer={
            <a
              className="button button-green  mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_Integration">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'BNPL Suite',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/affordability.png',
            width: '1220',
            height: '924',
          }}
          heading="India's widest range of Buy Now Pay Later options"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <>
              <div className="max-w-[500px] text-base">
                Provide 30+ flexible payment options with card and cardless
                EMI, Pay Later and increase customer conversions on your
                ecommerce store by 30%.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green  mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://www.cashfree.com/buy-now-pay-later/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'Reduce Cart Abandonment',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/reduce-cart.png',
            width: '1220',
            height: '924',
          }}
          heading="Reduce cart abandonment"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <div className="max-w-[500px] text-base">
              Use Payment Links to target customers with abandoned carts for
              quick payment collection thereby improving conversion.
            </div>
          }
          footer={
            <a
              className="button button-green  mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_ReduceCartAbandonment">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 5,
    heading: 'Faster Settlements',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          img={{
            src: '/img/ecommerce/faster-settlements.png',
            width: '1220',
            height: '924',
          }}
          heading="Faster Settlements"
          alignStart={alignStart}
          contentClassName={contentClassName}
          imageClassName={imageClassName}
          headingClass={headingClass}
          body={
            <div className="max-w-[500px] text-base">
              Get your funds settled instantly any day, any time, even during
              bank holidays with Cashfree&apos;s ecommerce payment gateway.
              Reduce dependency on external credit. Use the steady cashflow for
              marketing campaigns and working capital&nbsp;requirements.
            </div>
          }
          footer={
            <a
              className="button button-green mt-[32px] md:mt-[40px] w-full md:w-auto"
              href="https://www.cashfree.com/instant-settlements/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];

const otherUser = [
  { id: 0, text: 'Refund deposits' },
  { id: 1, text: 'Send prize money' },
  { id: 2, text: 'Reimburse expenses' },
  { id: 3, text: 'Send money to users' },
];
export const refundSuiteData = [
  {
    id: 0,
    title: 'Instant Refunds',
    content:
      'Integrate with your internal product via simple API and trigger refunds to the original payment source instantly.',
    footer: (
      <>
        <div className="text-cf-yellow text-base font-semibold">Use Case</div>
        <span className="block mt-3 md:mt-2 text-2.5sm md:text-base">
          <b className="font-semibold">Nykaa</b> uses Cashfree to send speedy
          refunds to customers. Once the customer requests a refund, the
          operations team reviews it and money is transferred instantly to the
          customer&apos;s account.
        </span>
      </>
    ),
    image: {
      src: '/img/ecommerce/instantrefund.png',
      width: 808,
      height: 652,
      style: { maxWidth: 404 },
    },
  },
  {
    id: 1,
    title: <>Cash-on-delivery order refunds made&nbsp;easier</>,
    content: (
      <>
        The biggest problem with COD order refunds is the unavailability of
        payment details. We solved it with{' '}
        <a
          href="https://www.cashfree.com/cashgram/"
          target="_blank"
          className="text-cf-green underline underline-offset-2 hover:underline active:underline focus:underline"
          rel="noopener noreferrer">
          Cashgram.
        </a>
      </>
    ),
    footer: (
      <>
        <div className="text-cf-yellow text-base font-semibold mb-4 md:mb-0">
          Other uses
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-[500px] text-2.5sm md:text-base">
          {otherUser.map(({ id, text }) => (
            <li className="mt-3 md:first:mt-3 first:mt-0 flex" key={id}>
              <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-white bg-opacity-20 flex-none">
                <Icon name="tick" width="9.21px" height="6.29px" fill="#fff" />
              </span>
              <h3 className="font-body !m-0 text-2.5sm md:text-base">{text}</h3>
            </li>
          ))}
        </div>
      </>
    ),
    image: {
      src: '/img/ecommerce/cod.png',
      width: 942,
      height: 628,
      style: { maxWidth: 471 },
    },
  },
];

export const faqData = [
  {
    id: 0,
    q:
      'How do I start using Cashfree Payment Gateway for my Ecommerce business?',
    a: (
      <>
        <p>
          We do 100% paperless onboarding for merchants on Cashfree Payments.
          Follow the below steps for quick account activation and start using
          Cashfree Payments in no time:
        </p>
        <ul className="list-decimal">
          <li>
            <a
              href="https://merchant.cashfree.com/merchants/signup"
              target="_blank"
              className="external-link"
              rel="noreferrer">
              Create your account
            </a>{' '}
            on Cashfree Payments
          </li>
          <li>
            Update your business profile and upload scanned copies of business
            documents
          </li>
          <li>
            Choose the product you are interested in. You can switch to Test
            Environment and try out the product features and integrations using
            test API credentials. Once ready, you can switch to Production
            Environment.
          </li>
          <li>
            Once you create your account, our payment expert will call you in
            the next 24 working hours, share your business requirement and we
            will help you pick the right set of features.
          </li>
          <li>
            Once the account is verified, you will receive an account activation
            confirmation email. For more details refer to our{' '}
            <a
              href="https://docs.cashfree.com/docs/activate-account"
              target="_blank"
              className="external-link"
              rel="noreferrer">
              account activation guide
            </a>
            .
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 1,
    q:
      'Are the payment gateway charges fixed? Can I get tailored pricing for my Ecommerce business?',
    a: (
      <p>
        Although we have a standard pricing model. But, you can connect with our
        team to discuss your business requirement and get tailored pricing
        depending on the nature of your business and the volume of transactions.
        For more details,{' '}
        <a
          href="https://www.cashfree.com/contact-sales/"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          contact us
        </a>{' '}
        or visit the{' '}
        <a
          href="https://www.cashfree.com/payment-gateway-charges/"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          pricing page
        </a>
        .
      </p>
    ),
  },
  {
    id: 2,
    q: 'Can I split incoming customer payments with multiple stakeholders?',
    a: (
      <p>
        Yes with our solution{' '}
        <a
          href="https://www.cashfree.com/easy-split/split-payment-gateway/"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          Easy Split
        </a>
        , you can collect payments from customers via any digital method, deduct
        commissions, and split payments to vendors, partners & your own bank
        accounts.
      </p>
    ),
  },
  {
    id: 3,
    q: 'What are the payment modes supported by Cashree Payments?',
    a: (
      <>
        <p>
          Cashfree Payments supports the widest range of payment modes with a
          single integration. We support over 120+ payment modes such as Credit
          and Debit Card (Visa, Mastercard, Rupay, AMEX, Diners), Net Banking of
          more than 65 banks, Paytm and other wallets, UPI via BHIM UPI, Google
          Pay, PhonePe, EMI options, buy now pay later and so on.
        </p>
        <p>
          We also provide multiple bank EMI and cardless EMI options such as
          Zest Money. With Payment Gateway, you can also give your customers a
          buy now pay later option by using Ola Money Postpaid, ePayLater, etc.
          Our merchants say that the buy now pay later option has increased the
          checkout success rate by 25-30% for high-ticket value transactions.
          For details, visit the pricing page.
        </p>
        You can find more about payment modes here:
        <br />
        <a
          href="https://docs.cashfree.com/docs/payment-methods"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          https://docs.cashfree.com/docs/payment-methods
        </a>
      </>
    ),
  },
  {
    id: 4,
    q: 'Can I accept international payments with Cashree Payments Gateway?',
    a: (
      <>
        <p>
          Cashfree Payments domestic and international online payment gateway is
          built for growing businesses. We support 100+ payment modes both
          domestic and international to help you go&nbsp;global.
        </p>
        <p>
          For domestic payments: We support 65+ net banking options, Credit
          Card, Debit Card, NEFT, IMPS, Paytm and other wallets, UPI via BHIM
          UPI, Google Pay, PhonePe etc. We also provide multiple bank EMI and
          cardless EMI options such as Zest Money, along with buy now pay later
          option (Ola Money Postpaid, ePayLater etc.)
        </p>
        <p>
          For international customers: With support for over 30+ international
          currencies, show your products to your customers in their preferred
          currency. Accept international payments via popular cards including
          Visa, Mastercard, Amex, Diners Club, and PayPal.
        </p>
        <p>
          We also have PayPal Express Checkout: Cashfree Payments offers direct
          integration with PayPal. Now connect your PayPal business account with
          Cashfree Payments and accept payments from over 350 mn PayPal users
          across 200 markets worldwide using PayPal Express Checkout from day
          one.
        </p>
        Know more here:
        <br />
        <a
          href="https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          https://knowledgebase.cashfree.com/support/solutions/articles/48001176153-paypal-exclusive-offer-paypal-express-checkout-on-cashfree-payment-gateway-
        </a>
      </>
    ),
  },
  {
    id: 5,
    q:
      'Does Cashfree Payments Gateway have the functionality to configure offers on the checkout page?',
    a: (
      <p>
        Yes with Cashfree’s{' '}
        <a
          href="https://www.cashfree.com/blog/offer-engine-on-cashfree-checkout-flow/"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          Offer Engine
        </a>
        , you can create, configure and manage offers across all online payment
        modes directly from the Cashfree Payment Gateway dashboard.
      </p>
    ),
  },
];
export const solutionCheckList = [
  {
    id: 0,
    text: 'Verify vendors during onboarding',
  },
  {
    id: 1,
    text: 'Vendor payouts',
  },
  {
    id: 2,
    text: 'Customer refunds',
  },
  {
    id: 3,
    text: 'One account, seamless payments',
  },
];
export const solutionCards = [
  {
    id: 0,
    title: 'Ready for 24/7 Payouts?',
    text: 'Works 24x7, even on bank holidays.',
    imageEdge: true,
    image: {
      src: 'solution-1.png',
      width: '676',
      height: '648',
    },
  },
  {
    id: 1,
    title: 'Want to make 10,000+ payouts in seconds?',
    text:
      'Add beneficiaries & do up to 10,000 bulk payouts in one go with simple excel file upload. Track transfer status in real time.',
    imageEdge: false,
    image: {
      src: 'solution-2.png',
      width: '664',
      height: '552',
    },
  },
  {
    id: 2,
    title: 'Need instant beneficiary payouts with control?',
    text:
      'Get flexibility on the fund source you want to disburse the money from and the transfer mode. Exercise control with Maker & Checker option.',
    imageEdge: false,
    image: {
      src: 'solution-3.png',
      width: '618',
      height: '572',
    },
  },
  {
    id: 3,
    title: 'Verify vendors for safe Payouts?',
    text:
      'Verify vendors during onboarding or before making payouts & avoid reversals & failed transfers.',
    imageEdge: false,
    image: {
      src: 'solution-4.png',
      width: '698',
      height: '514',
    },
  },
  {
    id: 4,
    title: 'Want industry-leading success rate?',
    text:
      'Get industry leading success rate at 99.98%, using smart routing via multiple gateways.',
    imageEdge: false,
    image: {
      src: 'solution-5.png',
      width: '646',
      height: '560',
    },
  },
];
export const solutionSettings = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  nextArrow: <SliderButton left={false} />,
  prevArrow: <SliderButton />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};
