import React, { useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import Tabs from '../components/Tabs/Tabs';
import PrimaryOfferSection from '../components/common/PrimaryProvideContainer/PrimaryOfferSection';
import CheckList from '../components/CheckList/CheckList';
import SolutionSlider from '../components/Sliders/SolutionSlider/SolutionSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  ecommerceStore,
  faqData,
  heroCheckList,
  heroLogoData,
  heroSliderSettings,
  paymentGatewayData,
  refundSuiteData,
  solutionCards,
  solutionCheckList,
  solutionSettings,
} from '../content/e-commerce';
import '../styles/e-commerce.scss';

const InstantSettlements = (props) => {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=ecommerce%20lp&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Best Ecommerce Payment Gateway for your Business"
        description="Collect online payments via UPI, Netbanking & 120+ payment options with the best ecommerce payment gateway."
        keywords={[
          'ecommerce payment gateway',
          'ecommerce payment',
          'payment gateway for ecommerce',
          'ecommerce payments',
          'ecommerce payment reconciliation',
          'Cart abandonment',
          'payment gateway for ecommerce website',
          'payment gateway in ecommerce',
          'ecommerce with payment gateway',
          'ecommerce payment platform',
          'ecommerce split payments',
          'payment gateway integration in ecommerce',
          'payment gateway ecommerce',
          'ecommerce payment platforms',
        ]}
        url="https://www.cashfree.com/solutions/ecommerce-payment-gateway/"
      />

      <section className="overflow-hidden relative pt-[110px] lg:pt-[70px] pb-[48px] md:pb-[64px] ecommerce-hero bg-cf-primary text-white">
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:w-[110%]">
            <div className="relative z-[1] w-full lg:self-center">
              <h1 className="text-sm font-semibold text-white pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Ecommerce Payment System
              </h1>
              <div className="mb-5 text-xl md:text-hero font-semibold  font-heading">
                <h2 className="inline">Ecommerce payment</h2>s collection for
                growing D2C businesses
              </div>
              <div className="text-opacity-80 mb-[30px] text-[16px] leading-[24px] md:text-2.5md">
                <h2 className="font-body inline">
                  Best e-commerce Payment Gateway
                </h2>{' '}
                for your business. Most optimised checkout experience on website
                and app, reduce cart abandonment, get access to working capital,
                process refunds faster and sell globally.
              </div>

              <ul className="p-0 mt-6 md:mt-7 mb-4 md:mb-[25px] list-none max-w-full flex flex-col md:flex-row md:items-center justify-start">
                {heroCheckList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] mr-[24px] md:mr-[16px] last:mr-0 flex items-center"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white bg-opacity-25">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <a
                className="button button-green w-full md:w-auto  md:mr-4"
                href="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_Hero">
                Create Account <span className="chevron" />
              </a>
            </div>
            <div className="w-full">
              <div className="w-full lg:w-[110%] lg:max-w-[110%] inset-video relative border-none z-10 hidden lg:block">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10 "
                  width="1700"
                  height="1300"
                  mp4Src="/img/ecommerce/hero-video.mp4"
                  webmSrc="/img/ecommerce/hero-video.webm"
                  fallBack="#6934d4"
                  // useFallBack
                />
              </div>
              <div className="w-full inset-video relative border-none z-10  lg:hidden mt-6">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10 "
                  width="1700"
                  height="1300"
                  mp4Src="/img/ecommerce/hero-video-mb.mp4"
                  webmSrc="/img/ecommerce/hero-video-mb.webm"
                  fallBack="#6934d4"
                  // useFallBack
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden z-10 w-full mt-[36px]">
          <HeroLogoSlider
            data={heroLogoData}
            imgUrl="/img/slider-logo/light"
            className=""
            itemClassName="mx-[25px] lg:mx-auto flex items-center align-center h-[50px]"
            settings={heroSliderSettings}
          />
        </div>
      </section>
      <section className="relative bg-cf-light-grey p-section-2 ecommerce-store overflow-hidden">
        <div className="container">
          <div className="text-shs md:text-shl mb-3 md:mb-5  font-heading font-semibold">
            E-Commerce store -{' '}
            <span className="block md:inline">We have you covered</span>
          </div>
          <div className=" text-[16px] leading-[24px] md:text-2.5md mb-8">
            Grow your business with our 360-degree{' '}
            <h2 className="inline font-body">e-commerce payments suite</h2>
          </div>
          <Tabs
            dropDown
            dropDownFontSize="text-[16px]"
            timerAutoPlay
            data={ecommerceStore.map((store) => ({
              key: store.id,
              heading: store.title,
              content: (
                <div className="md:mt-16 pb-0">
                  <div className="flex flex-col pt-[1px]">
                    <div className="text-3md  font-semibold mb-6 md:block hidden">
                      {store.title}
                    </div>
                    <div className="flex flex-wrap mx-[-19px] md:mt-[-38px] items-stretch">
                      {store.cards.map((card) => (
                        <div
                          className="px-[19px] pb-[16px] last:pb-2 last:md:pb-0 md:pb-0 md:pt-[38px] min-h-[122px] md:min-h-[257px] w-full md:w-1/3 lg:w-1/4"
                          key={card.id}>
                          <a
                            href={card.href}
                            className="px-[16px] py-[24px] bg-white  border-cf-stroke text-cf-hero border-opacity-10 h-full border rounded flex md:flex-col hover-link md:hover:scale-105 md:focus:scale-105 md:active:scale-105 transition-all duration-300 ">
                            <div className="w-[60px] h-[60px] flex-shrink-0 pt-2 pr-2 md:pt-0 md:pr-0">
                              <img
                                src={`/img/ecommerce/${card.icon}`}
                                alt={card.name}
                                width="60"
                                height="60"
                              />
                            </div>
                            <div>
                              <div className="md:mt-4 mb-2 text-md font-semibold button button-unstyled flex items-center text-cf-hero  ">
                                {card.name}
                              </div>
                              <div className="text-2.5sm">{card.text}</div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ),
            }))}
          />
        </div>
      </section>

      <section className="relative pt-[48px] md:py-[128px]">
        <div className="container api-banking-dropdown">
          <h2 className="text-shs md:text-shl font-semibold max-w-[600px] mb-6 md:mb-8 font-heading">
            Best Payment Gateway for your e-commerce store
          </h2>
          <Tabs
            data={paymentGatewayData}
            dropDown
            dropDownFontSize="text-[16px]"
            timerAutoPlay
          />
        </div>

        <PrimaryOfferSection
          className="mt-[48px] md:mt-[128px]"
          title={
            <>
              <div className="text-sm hidden md:block font-semibold text-white pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                New
              </div>
              Provide promotional and festive offers to customers
              at&nbsp;checkout
            </>
          }
          text="Attract customers and improve sales by providing cashback or discount on your website or apps. Restrict the payment methods, define the dates on which the offers are applicable."
          link="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_ProvidePromotionalOffers"
          buttonClassName="mb-8 md:mb-0 md:mt-[17.5px]"
          linkText="Create Account"
          image={
            <img
              className="relative md:w-4/5 mx-auto md:max-w-[425px]"
              src="/img/ecommerce/festive-offers.png"
              width="850"
              height="746"
              alt=""
            />
          }
        />
      </section>
      <section className="relative p-section-2 bg-cf-light-grey ecommerce-fullstack overflow-hidden">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[343px] md:max-w-[610px] mb-6 md:mb-8 font-heading md:text-center md:mx-auto">
            A full-stack, connected payments disbursal solution
          </div>

          <CheckList
            data={solutionCheckList}
            className="flex flex-col md:flex-row flex-wrap justify-center mb-7 md:mb-[41px]"
            itemMargin="mb-4 md:mr-6 md:last:mr-0"
          />

          <SolutionSlider
            data={solutionCards}
            settings={solutionSettings}
            baseImageUrl="/img/ecommerce"
          />
        </div>
      </section>
      <section className="relative bg-cf-dark p-section-2 ecommerce-refundsuite overflow-hidden">
        <div className="container  text-white">
          <div className="font-heading text-lg md:text-xl font-semibold max-w-[700px] mb-3 md:mb-6">
            Cashfree Payments’ Refunds Suite: Delight customers with faster
            refunds
          </div>
          <div className="text-cf-cold-purple text-2.5sm md:text-base mb-8  md:mb-6">
            Gain customer trust, enhance user experience and increase customer
            retention.
          </div>
          <div className="flex flex-wrap">
            {refundSuiteData.map(({ id, image, content, title, footer }) => (
              <div
                className="relative md:flex-row w-full flex flex-wrap card bg-white bg-opacity-10 mb-[16px] md:mb-0 last:mb-0 md:mt-10 rounded even:md:flex-row-reverse md:items-center p-6 md:px-[8.33%] md:py-[48px]"
                key={id}>
                <div className="w-full md:w-1/2">
                  <div className="text-3md md:text-4md font-semibold relative z-10 font-body max-w-[400px]">
                    {title}
                  </div>
                  <div className="pt-[8px] md:pt-3 pb-8 text-2.5sm md:text-md">
                    {content}
                  </div>
                  {footer}
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    alt={title}
                    {...image}
                    className="w-full relative z-10 card-image pt-7 md:pt-0"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="container general-faqs">
        <FAQ
          open="0"
          data={faqData}
          supportLinkHref="https://www.cashfree.com/help/hc"
        />
      </div>

      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=ecommerce%20lp&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=ecommerce%20lp&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default InstantSettlements;
